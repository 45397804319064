<template>
    <Card dis-hover>
        <div class="form_item">
            <div class="form_label must_input align_self_start">微信支付商户密钥</div>
            <div>
                <Input class="width_500" type="text" v-model.trim="formData.partnerKey" :maxlength="200" show-word-limit></Input>
                <div class="tip_txt">微信支付配置的API密钥</div>
            </div>
        </div>
        <div class="form_item">
            <div class="form_label must_input align_self_start">微信支付商户号</div>
            <div>
                <Input class="width_500" type="text" v-model.trim="formData.mchId" :maxlength="100" show-word-limit></Input>
                <div class="tip_txt">微信支付分配的商户号</div>
            </div>
        </div>
        <div class="form_item">
            <div class="form_label must_input align_self_start">商户平台商户全称</div>
            <div>
                <Input class="width_500" type="text" v-model.trim="formData.mch_name" :maxlength="100" show-word-limit></Input>
                <div class="tip_txt color_yellow">微信支付分配的商户号商户全称，如果开启分账，此项必须真实填写</div>
            </div>
        </div>
        <div class="form_item">
            <div class="form_label must_input align_self_start">apiclient_cert.pem</div>
            <div>
                <Input type="textarea" :rows="7" class="width_500" v-model.trim="formData.ssl_cert"></Input>
                <div class="tip_txt color_yellow">证书apiclient_cert.pem 文件，用记事本打开apiclient_cert.pem文件，将内容复制到以上文本框中。退款、红包会用到此证书)</div>
            </div>
        </div>
        <div class="form_item">
            <div class="form_label must_input align_self_start">apiclient_key.pem</div>
            <div>
                <Input type="textarea" :rows="7" class="width_500" v-model.trim="formData.ssl_key"></Input>
                <div class="tip_txt color_yellow">证书 apiclient_key.pem 文件，用记事本打开apiclient_key.pem文件，将内容复制到以上文本框中，(退款、红包会用到此证书</div>
            </div>
        </div>

        <div class="form_item">
            <div class="form_label align_self_start">退款资源来源</div>
            <div>
                <RadioGroup v-model="formData.refund_account" type="button" button-style="solid">
                    <Radio :label="refundAccountDef.unSettledFunds">未结算资金退款(默认)</Radio>
                    <Radio :label="refundAccountDef.rechargeFunds">可用余额退款</Radio>
                </RadioGroup>
                <div class="tip_txt color_yellow">仅针对老资金流商户使用。</div>
            </div>
        </div>

        <div class="mt_50 form_item">
            <div class="form_label no"></div>
            <Button type="success" :loading="ajaxLoading" @click="onConfirmEdit">保存</Button>
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </Card>
</template>
<script>
import { reqWxPaySet, reqWxPaySetView } from '@/lib/request/auth2';
const refundAccountDef = {
    unSettledFunds: 'REFUND_SOURCE_UNSETTLED_FUNDS',
    rechargeFunds: 'REFUND_SOURCE_RECHARGE_FUNDS',
};
export default {
    name: 'paySetting',
    components: {
        //
    },
    data() {
        this.refundAccountDef = refundAccountDef;
        return {
            title: '配置信息',
            formData: {
                partnerKey: null,
                mchId: null,
                mch_name: null,
                ssl_cert: '',
                ssl_key: null,
                refund_account: refundAccountDef.unSettledFunds,
            },
            isEdit: false,
        };
    },
    mounted() {
        this.routeIdInject();
        this.getDetail();
    },
    watch: {
        //
    },
    methods: {
        //获取详情
        getDetail() {
            this.showAjaxLoading();
            reqWxPaySetView()
                .then(res => {
                    this.dataInjectFormData(res);
                    // this.arrPic = this.formData.pic.split(this.picSeq);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //确认
        onConfirmEdit() {
            this.showAjaxLoading();
            let params = window.structuredClone(this.formData);
            let { partnerKey, mchId, mch_name, ssl_cert, ssl_key, refund_account } = params || {};
            let vs = [
                { value: partnerKey, tip: '请输入微信支付商户密钥' },
                { value: mchId, tip: '请输入微信支付商户号' },
                { value: mch_name, tip: '请输入商户平台商户全称' },
                { value: ssl_cert, tip: '请输入apiclient_cert.pem' },
                { value: ssl_key, tip: '请输入apiclient_key.pem' },
            ];

            hm.validateForm(vs)
                .then(() => {
                    reqWxPaySet(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                        })
                        .finally(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
    },
};
</script>
<style lang="less" scoped>
.tip_txt.color_yellow {
    font-weight: bold;
    color: #ea781f;
}
.form_label.no {
    &::after {
        content: '';
    }
}
</style>
